<template>
  <div>
    <CRow>
      <CCol class="col-12 col-md-6">
        <CCard>
          <CCardHeader>
            <strong>İletişim Formu</strong>
          </CCardHeader>
          <CCardBody>
            <CRow class="d-flex">
              <CCol class="col-2 col-md-3">
                <CInput placeholder="ID" disabled/>
              </CCol>
              <CCol class="col-10 col-md-6">
                <CInput placeholder="İsim Soyisim" disabled/>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="col-12 col-md-9 mt-4">
                <CInput placeholder="Tarih" disabled/>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="col-12 col-md-9 mt-4">
                <CInput placeholder="Konu Başlığı" disabled/>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="col-12 col-md-9 mt-4">
                <CTextarea placeholder="Konu İçeriği" disabled/>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "ContactFormDetails"
}
</script>

<style scoped>

</style>
